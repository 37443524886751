<template>
  <div class="layout-normal">
    <Header />
    <router-view />
    <Footer type="noraml" />
  </div>
</template>

<script>
import Header from '@/layouts/components/Header.vue'
import Footer from '@/layouts/components/Footer.vue'

export default {
  components: {
    Header,
    Footer,
  },
}
</script>
